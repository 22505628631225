header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 50px;
    z-index: 10000;
    transition: 0.5s; 
}

header .logo span {
    font-size: 1.40rem;
    font-weight: bold;
    letter-spacing: 1.5px;
    color: #fff;
}

.navigation {
    position: relative;
    display: flex;
    transition:1s ease-out;
    justify-content: center;
    align-items: center;
}

.navigation li {
    margin-left: 12px;
}

#lastchild {
    padding-right: 10px;
}

.navigation li a {
    letter-spacing: 0.5px;
    font-weight: 600;
    color: #fff;
    font-size: 1.10rem;
    transition: color 0.5s;
    padding-left: 4px;  
}



.navigation li a:hover {
    color: rgb(204, 214, 230);
}



#connect {
    padding: 13px 10px;
    color: black;
    background: #fff;
    transition: background 0.5s;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 12.5px;
    font-weight: 600;
    font-family: inherit;
    text-align: center;
}

#connect:hover {
    background: rgb(42, 48, 57);
    color: #fff;
}


#address {
    letter-spacing: 1px;
}


@media (max-width: 991px) {
    .navigation {
        display: none;
    }

    .footer .navigation {
        display: flex;
    }

    .toggle-menu {
        position: relative;
        background-image: url(../../pictures/icon-hamburger.svg);
        font-size: 4rem;
        height: 30px;
        width: 40px;
        background-size: 32px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        z-index: 1000;
    }


    .navigation.active {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: rgba(0, 0,0, 0.95);
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        transition:1s ease-out;
    }

   

    .navigation li a {
        color: #fff;
    }

    .navigation li {
        margin-bottom: 2rem;
        margin-left: 0;
    }

}

@media (max-width: 485px) {
    header {
        padding: 35px 26px;
    }
}

/* @media (max-width: 767px) {
    .navigation.active {
        width: 80%;
        left: 40px;
        top: 90px;
    }
} */