@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  background-color: #030303;
  font-family: "Josefin Sans", sans-serif;
  min-height: 100vh;
  color: #D4D4D4;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.btn {
  padding: 11px 15px;
  color: black;
  background: #fff;
  transition: background 0.5s ;
  text-transform: uppercase;
  cursor: pointer;
  border:none;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  font-family: inherit;
  text-align: center;
  letter-spacing: 0.3px;
}

.btn:hover {
  background: rgb(42, 48, 57);
  color: #fff;
}

.App {
  /* max-width: 1440px; */
  width: 100%;
  margin: auto;
}
