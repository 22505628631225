.about-content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 20px 0px;
    /* margin-top: 125px; */
    padding: 176px 0px 200px;
}

.about-content .textBox {
    position: relative;
    max-width: 550px;
    margin: 0px 100px 0px 20px;
    padding-left: 120px;
    
}

.LineChildren {
    transform: translate(0, 500px);
    padding-top:2px; 
}

/* .text-Header.bottom {
    padding-bottom: 20px;
} */


.LineParents {
   overflow: hidden;
   
}

.about-content .textBox h2 {
    color: #fff;
    font-size: 5em;
    line-height: 1em;
}

.about-content .textBox p {
    font-size: 1.1em;
    padding-bottom: 20px;
    line-height: 20px;
    color: #a4a9ac;
    font-weight: 400;
}

.about-content .imgBox {
    width: 600px;
    display: flex;
    justify-content: flex-start;
    
}

.about-content .imgBox img {
    max-width: 662px;
}


.about-content .play{
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 400;
    font-size: 1em;
}

.about-content .play img {
    max-width: 45px;
    margin-right: 10px;
    cursor: pointer;
}

.about-content .btn {
    height: 40px;
    width: 157px;
    background-color: #aed2f4;
    border: none;
    color: #000;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    font-weight: 700;
    font-size: 13px;
}

.about-content .btn:hover {
    transform: translateY(-5%);
}
   


@media (max-width:991px) {
    .about-content {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: column-reverse;
    }

    .about-content .imgBox {
        height: 350px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 50px;
    }

    .about-content .imgBox img {
        max-width: 100%;
    }

    .about-content .textBox {
        margin: 0px 20px 0px 0px;
        padding-right: 20px;
        padding-left: 49px;
    }
}


@media (max-width: 485px) {
    .about-content .textBox h4 {
        font-size: 0.7em;
    }

    .about-content .textBox h2 {
        font-size: 2.8em;
        margin-bottom: 11px;
    }

    .about-content .textBox p {
        font-size: 1em;
    }
    
    .about-content .btn {
        height: 36px;
        width: 150px;
        font-size: 12px;
    }

    .about-content .textBox {
        margin-left: 0px;
    }
}
