@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  background-color: #030303;
  font-family: "Josefin Sans", sans-serif;
  min-height: 100vh;
  color: #D4D4D4;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.btn {
  padding: 11px 15px;
  color: black;
  background: #fff;
  transition: background 0.5s ;
  text-transform: uppercase;
  cursor: pointer;
  border:none;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  font-family: inherit;
  text-align: center;
  letter-spacing: 0.3px;
}

.btn:hover {
  background: rgb(42, 48, 57);
  color: #fff;
}

.App {
  /* max-width: 1440px; */
  width: 100%;
  margin: auto;
}

.launch-link {
    position: absolute;
    top: 167px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: 0.5s;
} 


.links-Navigation {
    position: relative;
    display: flex;
    transition:1s ease-out;
    max-width: 800px;
    background-color: rgb(11, 17, 27);
    padding: 17px 0px;
    border-radius: 3px;
    
}

.links-Navigation li {
    margin-left: 20px;
    
}

.links-Navigation li a {
    letter-spacing: 0.3px;
    font-weight: 400;
    color: #fff;
    font-size: 1.20rem;
    transition: color 0.5s;    
}



.links-Navigation li a:hover {
    color: rgb(125, 119, 119);
}

a#lastChild {
    margin-right: 20px;
}


@media (max-width: 485px) {
    .links-Navigation li a {
        letter-spacing: 0.3px;
        font-weight: 400;
        color: #fff;
        font-size: 1rem;
        transition: color 0.5s;
    }

    .links-Navigation li {
        margin-left: 10px;
    }
    
}

.launchApp-container {
    min-height: 100vh;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.links-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.links-container h1 {
    font-size: 22px;
    max-width: 500px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 20px;
    line-height: 25px;
}


.submit-container {
    text-align: center;
}

.submit-container.two {
    margin-bottom: 9px;
}

.submit-container.tree {
    margin-bottom: 67px;
}

.submit-container h1 {
    max-width: 472px;
    padding: 0px 20px;
    font-size: 1.2rem;
    padding-bottom: 17px;
    line-height: 25px;
    font-weight: 400;
    margin-top: 29px;
}

.submit-container h4 {
    max-width: 472px;
    font-size: 1.1rem;
    padding-bottom: 17px;
    line-height: 25px;
    font-weight: 400;
}

.count-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 20px 0px;
}

button#count.btn {
    width: 40px;
    height: 37px;
    font-size: 1.3rem;
    padding-top: 8px;
}

p#count.btn {
    width: 40px;
    height: 37px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
}


#launchApp-btn {
    height: 40px;
    width: 150px;
    background-color: rgb(11, 17, 27);
    border: none;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;
}

#launchApp-btn:hover {
    background-color: #676b6e;
}



@media (max-width: 485px) {
    .submit-container h1 {
        font-size: 1.1rem;
    }

    .submit-container h4 {
        font-size: 1rem;
        margin: 0px 50px;
    }
}

.alert {
    font-size: 1.1rem;
    padding-bottom: 17px;
    font-weight: 400;
    color: #fff;
    text-align: center;
}
.borrowers-container {
    width: 95vw;
    display: grid;
    grid-template-columns: repeat(auto-fill,516px);
    justify-content: center;
}

.loan-container {
    display: flex;
    justify-content: center;
    align-items: center;
}


.single-borrower {
    background-color: rgb(11, 17, 27);
    color: #fff;
    border-radius: 6px;
    padding: 16px 0px;
    width: 439px;    
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    margin: auto;
    text-align: center;
    margin-top: 29px;
}

.single-borrower:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
}

.single-borrower h1 {
    color: #fff;
    font-weight: 500;
    padding: 2px 0px;
    margin-bottom: 1px;
    font-size: 18px;
    font-weight: 500;
}

.single-borrower h1 span {
    font-weight: 400;
    font-size: 17px;
    max-width: 50px;
    line-height: 24px;
}



.message {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(11, 17, 27);
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.loan-container.tree {
    margin-bottom: 20px;
}

.message:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
}


.borrowers-container.one {
    margin-bottom: -21px;
    /* margin-top: 180px; */
}


@media (max-width: 992px) {

    .borrowers-container.one {
        margin-bottom: -21px;
        margin-top: 198px;
    }
}


@media (max-width: 485px) {
    .single-borrower h1 {
        font-size: 17px;
    }

    .single-borrower h1 span {
        font-size: 15px;
    }

    .single-borrower {
        width: 371px;
    }

    
}







    


    
header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 50px;
    z-index: 10000;
    transition: 0.5s; 
}

header .logo span {
    font-size: 1.40rem;
    font-weight: bold;
    letter-spacing: 1.5px;
    color: #fff;
}

.navigation {
    position: relative;
    display: flex;
    transition:1s ease-out;
    justify-content: center;
    align-items: center;
}

.navigation li {
    margin-left: 12px;
}

#lastchild {
    padding-right: 10px;
}

.navigation li a {
    letter-spacing: 0.5px;
    font-weight: 600;
    color: #fff;
    font-size: 1.10rem;
    transition: color 0.5s;
    padding-left: 4px;  
}



.navigation li a:hover {
    color: rgb(204, 214, 230);
}



#connect {
    padding: 13px 10px;
    color: black;
    background: #fff;
    transition: background 0.5s;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 12.5px;
    font-weight: 600;
    font-family: inherit;
    text-align: center;
}

#connect:hover {
    background: rgb(42, 48, 57);
    color: #fff;
}


#address {
    letter-spacing: 1px;
}


@media (max-width: 991px) {
    .navigation {
        display: none;
    }

    .footer .navigation {
        display: flex;
    }

    .toggle-menu {
        position: relative;
        background-image: url(/static/media/icon-hamburger.7764b7eb.svg);
        font-size: 4rem;
        height: 30px;
        width: 40px;
        background-size: 32px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        z-index: 1000;
    }


    .navigation.active {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: rgba(0, 0,0, 0.95);
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        transition:1s ease-out;
    }

   

    .navigation li a {
        color: #fff;
    }

    .navigation li {
        margin-bottom: 2rem;
        margin-left: 0;
    }

}

@media (max-width: 485px) {
    header {
        padding: 35px 26px;
    }
}

/* @media (max-width: 767px) {
    .navigation.active {
        width: 80%;
        left: 40px;
        top: 90px;
    }
} */
.content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 71px 0px;
}

.LineChildren {
    -webkit-transform: translate(0, 500px);
            transform: translate(0, 500px);
    padding-top:2px; 
}

/* .text-Header.bottom {
    padding-bottom: 20px;
} */


.LineParents {
   overflow: hidden;
   
} 

.content .textBox {
    position: relative;
    max-width: 500px;
    margin-left: 50px;
}

.content .textBox h2 {
    color: #fff;
    font-size: 5em;
    line-height: 1em;
    margin-top: 25px;
}

.content .textBox h4 {
    color: #fffca1 ;
    font-size: 1em;
    line-height: 1em;
    margin-top: 20px;
    letter-spacing: 0.5px;
}

.content .textBox p {
    font-size: 1.1em;
    padding-bottom: 20px;
    line-height: 21px;
    color: #a4a9ac;
    font-weight: 400;
}

.content .imgBox {
    width: 600px;
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
}

.content .imgBox img {
    max-width: 662px;
}

.button-container #btn-left {
    margin-right: 10px;
}


.content .play{
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 400;
    font-size: 1em;
}

.content .play img {
    max-width: 45px;
    margin-right: 10px;
    cursor: pointer;
}

.content .btn {
    height: 40px;
    width: 157px;
    background-color: #aed2f4;
    border: none;
    color: #000;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    font-weight: 700;
    font-size: 13px;
}

.content .btn:hover {
    -webkit-transform: translateY(-6%);
            transform: translateY(-6%);
}


@media (max-width:991px) {
    .content {
        justify-content: center;
        align-items: flex-start;
        flex-direction:column;
        padding-top: 132px;
    }

    .content .imgBox {
        display: none;
    }

}


@media (max-width: 485px) {
    .content .textBox h4 {
        font-size: 0.8em;
    }

    .content .textBox h2 {
        font-size: 3.8em;
    }

    .content .textBox p {
        font-size: 1em;
    }
    
    .content .btn {
        height: 36px;
        width: 147px;
        font-size: 12px;
    }

    .content .textBox {
        margin-left: 30px;
    }
}
.Footer {
  background-color:rgb(11, 17, 27);
  color: #fff;
  margin: 0 auto;
  /* max-width: 1440px; */
  width: 100%;
  height: 130px;
}

.Footer .container {
  max-width: 1448px;
  height: 100%;
  width: auto;
  padding: 33px 10px 33px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.Footer .container .row {
  display: flex;
  justify-content:space-around;
  flex-direction: column;
  align-items: revert;
}

.Footer .container .social-media {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

.icon {
  color: #fff;
  font-size: 25px;
  margin-left: 15px;
  transition: 0.5s;
}

.Footer .container .navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.Footer .container .navigation li a {
  font-size: 1.40rem;
}



.icon:hover {
  color: rgb(42, 48, 57);
}

.navigation li:first-child {
  margin-left: 0px;
}

.logo {
  max-width: 40%;
}

.message-legal p {
  letter-spacing: 1px;
}


@media (max-width: 992px) {

  .Footer {
    height: 282px;
  }

  .Footer .container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

  .Footer .container .row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 18px; 
}

}


@media (min-width: 320px) and (max-width: 480px) {
  
  .Footer .container .navigation li a {
    font-size: 2.2rem;
  }

  .Footer .container .navigation li {
    margin-bottom: 0;
  }

  .Footer .container .navigation {
  flex-direction: column;
}

.Footer {
  height: 266px;
}

.logo {
  max-width: 80%;
}

.message-legal p {
  font-size: 13px;
  text-align: center;
  line-height: 20px;
} 
  
}
.about-content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 20px 0px;
    /* margin-top: 125px; */
    padding: 176px 0px 200px;
}

.about-content .textBox {
    position: relative;
    max-width: 550px;
    margin: 0px 100px 0px 20px;
    padding-left: 120px;
    
}

.LineChildren {
    -webkit-transform: translate(0, 500px);
            transform: translate(0, 500px);
    padding-top:2px; 
}

/* .text-Header.bottom {
    padding-bottom: 20px;
} */


.LineParents {
   overflow: hidden;
   
}

.about-content .textBox h2 {
    color: #fff;
    font-size: 5em;
    line-height: 1em;
}

.about-content .textBox p {
    font-size: 1.1em;
    padding-bottom: 20px;
    line-height: 20px;
    color: #a4a9ac;
    font-weight: 400;
}

.about-content .imgBox {
    width: 600px;
    display: flex;
    justify-content: flex-start;
    
}

.about-content .imgBox img {
    max-width: 662px;
}


.about-content .play{
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 400;
    font-size: 1em;
}

.about-content .play img {
    max-width: 45px;
    margin-right: 10px;
    cursor: pointer;
}

.about-content .btn {
    height: 40px;
    width: 157px;
    background-color: #aed2f4;
    border: none;
    color: #000;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    font-weight: 700;
    font-size: 13px;
}

.about-content .btn:hover {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
}
   


@media (max-width:991px) {
    .about-content {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: column-reverse;
    }

    .about-content .imgBox {
        height: 350px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 50px;
    }

    .about-content .imgBox img {
        max-width: 100%;
    }

    .about-content .textBox {
        margin: 0px 20px 0px 0px;
        padding-right: 20px;
        padding-left: 49px;
    }
}


@media (max-width: 485px) {
    .about-content .textBox h4 {
        font-size: 0.7em;
    }

    .about-content .textBox h2 {
        font-size: 2.8em;
        margin-bottom: 11px;
    }

    .about-content .textBox p {
        font-size: 1em;
    }
    
    .about-content .btn {
        height: 36px;
        width: 150px;
        font-size: 12px;
    }

    .about-content .textBox {
        margin-left: 0px;
    }
}

.contacts {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000;
    color: #fff;
    padding: 110px 0px 50px;
    /* padding-top: 50px; */
    width: 100%;
  }

.checkbox-container {
  display: flex;
}


.checkbox-container label  {
  text-align: left;
  margin-left: 5px;
  line-height: 20px;
}

.contacts .title {
    text-align: center;
    margin: 40px 0 24px;
}

.contacts .title h1 {
    font-size: 3.5rem;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.5px;
}

.contacts .title p {
    font-size: 1.3em;
    font-size: 500;
    letter-spacing: 0.5px;
    padding-top: 10px;
    line-height: 25px;
    max-width: 500px;
}

.contacts .book-form {
    position: relative;
    padding: 0px 60px 60px 60px;
    display: inline-block;
    text-align: center;
    
}

.contacts .book-form .inputBox {
    display: flex;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
}


.contacts .book-form .inputBox input,
.contacts .book-form .inputBox textarea {
    padding: 13px;
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    color:#111;
    outline: none;
    font-family: inherit;
    border-radius: 5px;
    border: none;
    
}

.contacts .book-form .inputBox #uploadFile {
    color: #fff;
}


.contacts .book-form .inputBox input[type = "submit"] {
    display: inline-block;
    background:#aed2f4;
    color: #000;
    border: none;
    height: 40px;
    font-weight: 700;
    margin-top: 10px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.5s;
    border-radius: 5px;   
}



.contacts .book-form .inputBox input[type = "submit"]:hover {
    background:#383dda;
}

.submit-alert {
  text-align: center;
  line-height: 19px;
  font-weight: 400;
}


@media screen and (min-width: 576px) {
    .contacts {
      width: 70%;
    }
  }

  @media screen and (min-width: 768px) {
    .contacts {
      width: 55%;
    }
  }

  @media screen and (min-width: 992px) {
    .contacts {
      width: 45%;
    }
 
  }

  @media screen and (min-width: 1200px) {
    .contacts {
      width: 35%;
    }

  }

  @media screen and (max-width: 420px) {
    .contacts .title p {
        font-size: 1em;
        max-width: 320px;
    }
  }


  @media (max-width: 485px) {
    .contacts .book-form .inputBox input[type = "submit"] {
      height: 36px;
      font-size: 12px;
    }

    .contacts .title h2 {
      font-size: 2.7em;
    }
    
}
.admin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000;
    color: #fff;
    padding: 110px 0px 50px;
    /* padding-top: 50px; */
    width: 100%;
  }


.admin-container .title {
    text-align: center;
    margin: 40px 0 24px;
}

.admin-container .title h1 {
    font-size: 3.5rem;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.5px;
}

.admin-container .title p {
    font-size: 1.3em;
    font-size: 500;
    letter-spacing: 0.5px;
    padding-top: 10px;
    line-height: 25px;
    max-width: 500px;
    text-align: center;
}

.admin-container .book-form {
    position: relative;
    padding: 0px 60px 60px 60px;
    display: inline-block;
    /* text-align: center; */
    width: 100%;
    
}

.admin-container .book-form .inputBox {
    margin-bottom: 20px;
    position: relative;
}


.admin-container .book-form .inputBox label {
     display: block;
     margin-bottom: 5px;
}


.admin-container .book-form .inputBox input,
.admin-container .book-form .inputBox textarea {
    padding: 13px;
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    color:#111;
    outline: none;
    font-family: inherit;
    border-radius: 5px;
    border: none;
    
}

.admin-container .book-form .inputBox #uploadFile {
    color: #fff;
}


.admin-container .book-form .inputBox input[type = "submit"] {
    display: inline-block;
    background:#aed2f4;
    color: #000;
    border: none;
    height: 40px;
    font-weight: 700;
    margin-top: 10px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.5s;
    border-radius: 5px;   
}



.admin-container .book-form .inputBox input[type = "submit"]:hover {
    background:#383dda;
}

.submit-alert {
  text-align: center;
  line-height: 19px;
  font-weight: 400;
}

.btn-create {
    border: 2px solid #BABED7;
    border-radius: 8px;
    display: block;
    width: 107%;
    font-size: 14px;
    height: 44px;
    background:#aed2f4;
    color: #000;
    margin-bottom: 10px;
    margin-top: 27px;
    cursor: pointer;
    outline: 0;
    font-weight: 600;
    font-family: inherit;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    
}


@media screen and (min-width: 576px) {
    .admin-container {
      width: 70%;
    }
  }

  @media screen and (min-width: 768px) {
    .admin-container {
      width: 55%;
    }
  }

  @media screen and (min-width: 992px) {
    .admin-container {
      width: 45%;
    }
 
  }

  @media screen and (min-width: 1200px) {
    .admin-container {
      width: 35%;
    }

  }
  
  @media screen and (max-width: 420px) {
    .admin-container .title p {
        font-size: 1em;
        max-width: 382px;
        text-align: center;
    }

    .admin-container .title h1 {
      font-size: 2.60rem;
    }
  }


  @media (max-width: 485px) {
    .admin-container .book-form .inputBox input[type = "submit"] {
      height: 36px;
      font-size: 12px;
    }

    .admin-container .title h2 {
      font-size: 2.7em;
    }
    
}
.howItWorks-content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0px;
}

.howItWorks-content .textBox {
    position: relative;
    max-width: 550px;
    margin: 0px 100px 0px 20px;
}

.howItWorks-content .textBox h2 {
    color: #fff;
    font-size: 5em;
    line-height: 1em;
    margin-bottom: 10px;
}

.LineChildren {
    -webkit-transform: translate(0, 500px);
            transform: translate(0, 500px);
    padding-top:2px; 
}

/* .text-Header.bottom {
    padding-bottom: 20px;
} */


.LineParents {
   overflow: hidden;
   
}

.howItWorks-content .textBox p {
    font-size: 1.1em;
    line-height: 20px;
    font-weight: 400;
}

.howItWorks-content .imgBox {
    width: 600px;
    display: flex;
    justify-content: flex-start;
    
}

.howItWorks-content .imgBox img {
    max-width: 662px;
}


.howItWorks-content .play{
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 400;
    font-size: 1em;
}

.howItWorks-content .play img {
    max-width: 45px;
    margin-right: 10px;
    cursor: pointer;
}

.howItWorks-content .btn {
    height: 40px;
    width: 155px;
    background-color: #aed2f4;
    border: none;
    color: #000;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    font-weight: 700;
    font-size: 13px;
}

.howItWorks-content .btn:hover {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
}

.howItWorks-content .textBox .requestLoan_list {
    padding: 20px 0px;
} 

.howItWorks-content .textBox .requestLoan_list li {
    padding-bottom: 5px;
    letter-spacing: 0.5px;
    line-height: 19px;
    color: #a4a9ac;
}  
   


@media (max-width:991px) {
    .howItWorks-content {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 90px 0px;
        flex-direction: column-reverse;
        margin-top: 70px;
    }

    .howItWorks-content .imgBox {
        height: 350px;
        width: 100%;
        display: flex;
        justify-content: start;
        padding-top: 50px;
    }

    .howItWorks-content .imgBox img {
        max-width: 100%;
    }

    .howItWorks-content .textBox {
        margin-left: 50px;
    }
}


@media (max-width: 485px) {
    .howItWorks-content .textBox h4 {
        font-size: 0.7em;
    }

    .howItWorks-content .textBox h2 {
        font-size: 2.8em;
    }

    .howItWorks-content .textBox p {
        font-size: 1em;
    }
    
    .howItWorks-content .btn {
        height: 36px;
        width: 155px;
        font-size: 12px;
    }

    .howItWorks-content .textBox {
        margin-left: 30px;
    }
}

.accordion-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px 0px 20px 0px;
  /* margin-top: 125px; */
}

.accordion-section .FAQTitle {
  font-size: 3rem;
  padding-bottom: 50px;
  text-align: center;
}

.accordion-section .container {
  /* padding-top: 160px; */
  padding-top: 90px;
}


.accordion-section .wrap {
  /* background-color: rgb(11, 17, 27); */
  background-color: #121e21;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  border-radius: 5px 5px 0px 0px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.accordion-section .wrap h1 {
  letter-spacing: 1px;
  padding: 1.8rem 8rem 1.8rem 2rem;
  font-size: 1.2rem;
  font-weight: 400;
}

.accordion-section .accordion-wrap {
  padding-bottom: 20px;
}

.accordion-section .wrap span {
    margin-right: 1.5rem;
}

.accordion-section .dropDown {
  /* background-color: rgb(11, 17, 27); */
  background-color: #192131;
  color: #00ffb9;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* border-bottom: 1px solid #00ffb9;
  border-top: 1px solid #00ffb9; */
  padding: 27px;
  transition: 1s ease-out;
  border-radius: 0px 0px 5px 5px;
  
}

.accordion-section .dropDown p {
    font-size: 2rem;
}


.OurTeam-section {
  padding: 20px 0px 20px 0px;
  /* margin-top: 125px; */
  position: relative;
  /* padding-top: 138px; */
  /* padding-top: 30px;  */
  padding: 140px 0px 150px;
}

.OurTeam-section .teamTitle {
  font-size: 3rem;
  padding-bottom: 15px;
  text-align: center;
  letter-spacing: 0.5px;
}

.OurTeam-section .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  /* padding-top: 160px; */
}


.OurTeam-section .card
{
   position: relative;
   width: 340px;
   height: 450px;
   background: #151636;
   overflow: hidden;
   border-radius: 10px;
   margin: 20px;
}

.OurTeam-section .card .circle
{
   position: absolute;
   top: -190px;
   left: 50%;
   width: 500px;
   height: 500px;
   -webkit-transform: translateX(-50%);
           transform: translateX(-50%);
   -webkit-clip-path: circle();
           clip-path: circle();
}
.OurTeam-section .card .circle::before
{
   content: '';
   position: absolute;
   top: -8px;
   left: -16px;
   width: 100%;
   height: 100%;
   /* box-shadow: 0 0 0 20px rgba(255,0,0,0.5); */
   box-shadow: 0 0 0 20px rgba(243, 184, 184, 0.5);
   border-radius: 50%;
   z-index: 2;
   pointer-events: none;
}
.OurTeam-section .circle .imgBox
{
   position: absolute;
   left: 50%;
   bottom: 0;
   -webkit-transform: translateX(-50%);
           transform: translateX(-50%);
   width: 340px;
   height: 310px;
}

.OurTeam-section .imgBox img
{
   position: absolute;
   top: 0;
   left: 0;
   object-fit: cover;
   width: 100%;
   height: 100%;
   transition: all 0.5s;
}


.OurTeam-section .imgBox img:hover
{
   -webkit-transform: scale(1.2);
           transform: scale(1.2);
   cursor: pointer;
}

.OurTeam-section .imgBox .img2,
.OurTeam-section .imgBox .img1
 {
   height: 500px;
}

.OurTeam-section .imgBox .img1 {
   -webkit-transform: scale(1.2);
           transform: scale(1.2);
}

.OurTeam-section .imgBox .avatar {
   display: none;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 99;
}

.OurTeam-section .imgBox:hover img {
   display: inline;
   background-color: black;
   transition: all 0.5s;
}





.OurTeam-section .card .content
{
   position: absolute;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 140px;
   padding: 20px 30px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-flow: column;
}
.OurTeam-section .content .fa-linkedin
{
   padding: 2px 4px;  
   color: #fff;
   background: #0077b5;
   border-radius: 2px;
}
.OurTeam-section .content h3
{
   font-size: 1.4em;
   color: #C0C7F7;
   font-weight: 400;
   margin-top: 7px;
   margin-bottom: 2px;
   letter-spacing: 0.5px;
}

.OurTeam-section .content .title h4
{
   color: #C0C7F7;
   padding: 4px 0px 8px 0px;
   letter-spacing: 0.5px;
   font-weight: 400;
   text-align: center;
   line-height: 19px;
}

.icons-content .icon {
   -webkit-filter: invert(0.2);
           filter: invert(0.2);
   font-size: 16px;
}

.icons-content {
   display: flex;
   justify-content: center;
   align-items: center;
}




.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .title-loader {
    font-size: 100px;
  }

  .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  @-webkit-keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
