.links-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.links-container h1 {
    font-size: 22px;
    max-width: 500px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 20px;
    line-height: 25px;
}

