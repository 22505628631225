.accordion-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px 0px 20px 0px;
  /* margin-top: 125px; */
}

.accordion-section .FAQTitle {
  font-size: 3rem;
  padding-bottom: 50px;
  text-align: center;
}

.accordion-section .container {
  /* padding-top: 160px; */
  padding-top: 90px;
}


.accordion-section .wrap {
  /* background-color: rgb(11, 17, 27); */
  background-color: #121e21;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  border-radius: 5px 5px 0px 0px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.accordion-section .wrap h1 {
  letter-spacing: 1px;
  padding: 1.8rem 8rem 1.8rem 2rem;
  font-size: 1.2rem;
  font-weight: 400;
}

.accordion-section .accordion-wrap {
  padding-bottom: 20px;
}

.accordion-section .wrap span {
    margin-right: 1.5rem;
}

.accordion-section .dropDown {
  /* background-color: rgb(11, 17, 27); */
  background-color: #192131;
  color: #00ffb9;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* border-bottom: 1px solid #00ffb9;
  border-top: 1px solid #00ffb9; */
  padding: 27px;
  transition: 1s ease-out;
  border-radius: 0px 0px 5px 5px;
  
}

.accordion-section .dropDown p {
    font-size: 2rem;
}

